export const Basket = [
    {symbol: "BTC", amount: 16, ticker: 'bitcoin', min: 2000, max: 1000000},
    {symbol: "ETH", amount: 257.5, ticker: 'ethereum', min: 500, max: 20000},
    {symbol: "USDC", amount: 73100, ticker: 'usd-coin', value: 73100, price: 1},
    {symbol: "DOT", amount: 6000, ticker: 'polkadot', min: 1, max: 1000},
    {symbol: "BNB", amount: 232.6, ticker: 'binancecoin', min: 100, max: 5000},
    {symbol: "ETHW", amount: 300, ticker: 'ethereum-pow-iou', min: 1, max: 1000},
    {symbol: "SKEY", amount: 850000, ticker: 'skey-network', min: 0.01, max: 10},
    {symbol: "zkSNP", amount: 45000, ticker: 'synapse-network-2', min: 0.001, max: 10},
    {symbol: "YF-DAI", amount: 30, ticker: 'yfdai-finance', min: 10, max: 10000},
    {symbol: "KNG", amount: 2170, ticker: 'kanga-exchange', min: 0.1, max: 1000},
    {symbol: "JUP", amount: 16000, ticker: 'jupiter-exchange-solana', min: 0.01, max: 100},
    {symbol: "FOUND", amount: 2000000, ticker: 'ccfound-2', min: 0.01, max: 100},
    // {symbol: "SSGTx", amount: 125000, ticker: 'safeswap-token', min: 0.001, max: 1},
    // {symbol: "MallVR", amount: 42, ticker: null, min: 1, max: 1000},
    {symbol: "CAKE", amount: 5350, ticker: 'pancakeswap-token', min: 0.1, max: 1000},
    {symbol: "ARI", amount: 917000, ticker: 'ari', min: 0.001, max: 10},
    {symbol: "OSAK", amount: 12370000000, ticker: 'osaka-protocol', min: 0.001, max: 10}
]

export const BinanceTokens = [
    {symbol: 'MANTA', amount: 93, ticker: 'MANTAUSDT', price: 1},
    {symbol: 'ALT', amount: 1533, ticker: 'ALTUSDT', price: 1},
    {symbol: 'PIXEL', amount: 2038, ticker: 'PIXELUSDT', price: 1},
    {symbol: 'PORTAL', amount: 280, ticker: 'PORTALUSDT', price: 1},
    {symbol: 'AEVO', amount: 243, ticker: 'AEVOUSDT', price: 1},
    {symbol: 'ENA', amount: 1574, ticker: 'ENAUSDT', price: 1},
    {symbol: 'SAGA', amount: 234, ticker: 'SAGAUSDT', price: 1},
    {symbol: 'OMNI', amount: 18.5, ticker: 'OMNIUSDT', price: 1},
    {symbol: 'REZ', amount: 1260, ticker: 'REZUSDT', price: 1},
    {symbol: 'BB', amount: 1833, ticker: 'BBUSDT', price: 1},
    {symbol: 'NOT', amount: 19970, ticker: 'NOTUSDT', price: 1},
    {symbol: 'IO', amount: 106, ticker: 'IOUSDT', price: 1},
    {symbol: 'LISTA', amount: 897, ticker: 'LISTAUSDT', price: 1},
    {symbol: 'DOGS', amount: 100000, ticker: 'DOGSUSDT', price: 1},
    {symbol: 'TON', amount: 42, ticker: 'TONUSDT', price: 1},
    {symbol: 'CATI', amount: 485, ticker: 'CATIUSDT', price: 1},
    {symbol: 'HMSTR', amount: 16231, ticker: 'HMSTRUSDT', price: 1},
    {symbol: 'SCR', amount: 290, ticker: 'SCRUSDT', price: 1},
    {symbol: 'THE', amount: 169, ticker: 'THEUSDT', price: 1},
    {symbol: 'MOVE', amount: 1619, ticker: 'MOVEUSDT', price: 1},
]

export const XTokens = [
    {symbol: 'FEG', ticker: 'feg-token-2', amount: 3280000},
    {symbol: 'ARI', ticker: 'ari', amount: 100000},
    {symbol: 'OSAK', ticker: 'osaka-protocol', amount: 8120000000},
    {symbol: 'CAW', ticker: 'crow-with-knife', amount: 72200000000},
    {symbol: 'ARC', ticker: 'arc', amount: 11540},
    {symbol: 'DEFIT', ticker: 'defit', amount: 4873},
]
